import { useMediaQuery } from 'react-responsive'

export const useResolution = () => {
  const isMinScreen768 = useMediaQuery({
    query: `(min-width:768px )`,
  })
  const isMinScreen1000 = useMediaQuery({
    query: `(min-width:1000px )`,
  })
  const isMinScreen1440 = useMediaQuery({
    query: `(min-width:1440px )`,
  })

  return { isMinScreen768, isMinScreen1000, isMinScreen1440 }
}
