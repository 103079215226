import {Png, Svg} from "common/assets"
import { LinkApps, StyledAppWrapper } from "./styled"

export const LoadingApps = ({ direction }: { direction?: string }) => {
    
    return (
        <StyledAppWrapper direction={direction}>
           <LinkApps href="https://apps.apple.com/us/app/restaron/id6447766070" target="_blank"><img src={Png.App} alt="icon app" /></LinkApps>
           <LinkApps href="https://play.google.com/store/apps/details?id=com.kitapp.restaron" target="_blank"><img src={Png.Google} alt="icon app" /></LinkApps>
        </StyledAppWrapper>
    )
}